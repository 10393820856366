import React, { memo } from "react";
import { GetServerSidePropsContext, GetServerSidePropsResult } from "next";
import { PageWrapper } from "components/PageWrapper";
import { IPageFe } from "models/client/PageFe";
import { Page } from "models/server/Page";
import { StaticContents } from "models/server/StaticContents";
import {
  MenuItem,
  PrivacyDisclaimer,
  StaticContentType,
} from "models/common/StaticContents";
import { Store } from "models/server/Store";
import { IStoreFe } from "models/client/StoreFe";
import { withIronSessionSsr } from "iron-session/next";
import { userSessionOptions } from "lib/session";
import { CCNOManager } from "models/server/CCNOManager";
import { UserProfile } from "models/common/UserCommon";
import { User } from "models/server/User";
import { Article } from "models/server/Article";
import { PressRelease } from "models/server/PressRelease";
import { PressReleaseCategory } from "models/server/PressReleaseCategory";
import { IArticleFe } from "models/client/ArticleFe";
import { FooterProps } from "components/_visual-components";
import { IPressReleaseFe } from "models/client/PressReleaseFe";
import { IPressReleaseCategoryFe } from "models/client/PressReleaseCategoryFe";
import { checkAdminPreview } from "helpers/checkAdminPreview";

type GenericPageProps = {
  isAdminPreview?: boolean;
  page: IPageFe;
  menuData: MenuItem[];
  footerContent: FooterProps;
  defaultStore: IStoreFe;
  userStore: IStoreFe;
  userProfile?: UserProfile;
  storeArticles?: IArticleFe[];
  mediumCardsArticles?: IArticleFe[];
  pressReleases?: IPressReleaseFe[];
  pressReleaseCategories?: IPressReleaseCategoryFe[];
  privacyDisclaimer: PrivacyDisclaimer;
};

const GenericPage = memo(
  ({
    isAdminPreview,
    page,
    menuData,
    footerContent,
    userStore,
    defaultStore,
    userProfile,
    storeArticles,
    mediumCardsArticles,
    pressReleaseCategories,
    pressReleases,
    privacyDisclaimer,
  }: GenericPageProps) => {
    return (
      <PageWrapper
        isAdminPreview={isAdminPreview}
        page={page}
        menuData={menuData}
        footerContent={footerContent}
        store={defaultStore}
        userStore={userStore}
        userProfile={userProfile}
        storeArticles={storeArticles}
        mediumCardsArticles={mediumCardsArticles}
        pressReleaseCategories={pressReleaseCategories}
        pressReleases={pressReleases}
        privacyDisclaimer={privacyDisclaimer}
      />
    );
  }
);

GenericPage.displayName = "GenericPage";

export default GenericPage;

export const getServerSideProps = withIronSessionSsr(
  async function getServerSideProps({
    params: { slugs },
    req: { session, cookies, headers },
  }: GetServerSidePropsContext<{ slugs: string[] }>): Promise<
    GetServerSidePropsResult<GenericPageProps>
  > {
    const slug = slugs ? slugs[slugs.length - 1] : "home";
    const page = await Page.getBySlug(slug);

    const isAdminPreview = await checkAdminPreview(cookies, headers);

    if (!page || (!page.published && !isAdminPreview)) {
      return {
        notFound: true,
      };
    }

    if (slug !== "home" && !page.matchesSlugs(slugs ?? [])) {
      return {
        notFound: true,
      };
    }

    const menuData: MenuItem[] =
      (await StaticContents.getByContentType(StaticContentType.Menu))?.data ??
      [];
    const footerContent: FooterProps = (
      await StaticContents.getByContentType(StaticContentType.Footer)
    )?.data ?? { columns: [] };
    const privacyDisclaimer: PrivacyDisclaimer =
      (await StaticContents.getByContentType(StaticContentType.Privacy))
        ?.data ?? null;

    let userProfile: UserProfile = null;
    if (session?.user) {
      try {
        userProfile = (
          await CCNOManager.getProfile({
            token: session.user.token,
          })
        ).payload.profile;
      } catch (e) {}
    } else {
      if (slug === "profilo-socio") {
        return {
          redirect: {
            destination: "/accesso-soci",
            permanent: false,
          },
        };
      }
    }

    const defaultStore = await Store.getByStoreId(2780);
    const userStore = await User.getFavouriteStore(session);
    const storeId = userStore?.id ?? defaultStore.id;

    const storeArticles = await Store.getStoreArticles(storeId);

    const pressReleases: PressRelease[] = await PressRelease.getList();
    const pressReleaseCategories: PressReleaseCategory[] =
      await PressReleaseCategory.getList();

    const mediumCardsArticles: Article[] = [];
    const mediumCardsTags = page.visualComponents.map((vc) =>
      vc.name === "MediumCards" ? vc.props.tag : []
    );

    if (mediumCardsTags.length > 0) {
      for (const tag of mediumCardsTags) {
        const articles = await Article.getList(
          { tags: tag, published: true },
          { limit: 3, skip: 0, sort: [{ by: "created", asc: false }] }
        );
        const slugs = mediumCardsArticles.map((ar) => ar.slug);
        articles.forEach(
          (ar) => !slugs.includes(ar.slug) && mediumCardsArticles.push(ar)
        );
      }
    }

    return {
      props: {
        isAdminPreview,
        page: page.toClientVersion(),
        menuData,
        footerContent,
        defaultStore: defaultStore.toClientVersion(),
        userStore: userStore?.toClientVersion() ?? null,
        userProfile,
        pressReleases: pressReleases.map((a) => a.toClientVersion()),
        pressReleaseCategories: pressReleaseCategories.map((a) =>
          a.toClientVersion()
        ),
        storeArticles: storeArticles.map((a) => a.toClientVersion()),
        mediumCardsArticles: mediumCardsArticles.map((a) =>
          a.toClientVersion()
        ),
        privacyDisclaimer,
      },
    };
  },
  userSessionOptions
);
